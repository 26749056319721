<template>
  <OnClickOutside
    @trigger="() => updateOpen(false)">

    <div class="relative">
      <button
        type="button"
        aria-label="Switch Accounts"
        :class="buttonClasses"
        class="hover:opacity-100 rounded-lg border py-0.5 px-3 transition-all flex gap-1 items-center focus:outline-transparent"
        @click="() => updateOpen(!props.open)">

        <span>{{ currentAccount.name }}</span>

        <ChevronUpDownIcon
          class="h-4 w-4 -mr-0.5"
          aria-hidden="true"/>

      </button>

      <TransitionDropdown>
        <div
          v-if="open"
          class="absolute top-full mt-3 left-0 z-50 flex flex-col items-start rounded bg-white px-2 text-slate-400 shadow md:block space-y-1 py-2">

          <template
            v-for="(account, index) in accountOptions" :key="account.id">

            <Component
              :is="account.href ? 'a' : 'button'"
              :type="!account.href && 'button'"
              :href="account.href"
              :class="account.id === currentAccount.id ? 'bg-bdBlue-100 text-bdBlue-600 font-semibold' : 'font-medium text-slate-500 hover:bg-bdBlue-50 hover:text-bdBlue-600'"
              class="flex items-center justify-start gap-3 py-2 px-4 text-sm text-left w-full rounded-lg"
              @click="switchAccount(account)">

              <AnimationSpinningLoader
                v-if="account.loading"
                diameter="1rem"
                border-size="2px"
                color="#487ec8"
                class="h-4 w-4"/>

              <Component
                v-else
                :is="account.icon"
                class="h-4 w-4"/>

              <span class="block text-left w-full pr-0.5">
                <span
                  class="whitespace-nowrap block">
                  {{ account.name }}
                </span>
                <span
                  v-if="account.subtitle"
                  :class="account.id === currentAccount.id ? 'text-bdBlue-400' : 'text-slate-300'"
                  class="text-xs block whitespace-nowrap">
                  {{ account.subtitle }}
                </span>
              </span>

            </Component>

            <div
              v-if="index !== accountOptions.length - 1"
              class="h-px w-full bg-slate-100 mx-2"/>

          </template>

        </div>
      </TransitionDropdown>
    </div>

  </OnClickOutside>
</template>

<script setup>
import AnimationSpinningLoader from "./AnimationSpinningLoader.vue";
import TransitionDropdown from "./TransitionDropdown.vue";
import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  ChevronUpDownIcon,
  LockClosedIcon,
  UserIcon,
  XCircleIcon
} from "@heroicons/vue/24/solid/index.js";
import {OnClickOutside} from "@vueuse/components";
import {computed, inject, nextTick} from "vue";
import {useAccountStore} from "../stores/AccountStore";
import {getEnterpriseNouns} from "../helpers/EnterpriseNouns";
import {useLogInAs} from "../composables/LogInAs";
import {sendToCorrectDashboard} from "../helpers/Redirects";
import {useRouter} from "vue-router";
import {getActivePinia} from "pinia";

const pageReload = inject("pageReload");

const emits = defineEmits(["update:open"]);
const props = defineProps({
  open: Boolean,
  useGradientBanner: Boolean,
});

const router = useRouter();
const accountStore = useAccountStore();

const updateOpen = (value) => emits("update:open", value);

const buttonClasses = computed(() => {
  let classes = "";

  if (props.useGradientBanner) classes += " text-white border-white/20";
  else classes += " text-slate-600 border-slate-300/75";

  if (props.open) classes += " opacity-90";
  else classes += " opacity-50";

  return classes;
});

const {
  loginAsCandidate: loginAsCandidateWithoutHandling,
  loginAsCompany: loginAsCompanyWithoutHandling,
  loginAsEnterprise: loginAsEnterpriseWithoutHandling,
  loggingInAsCandidate,
  loggingInAsCompanyId,
  loggingInAsEnterpriseId,
} = useLogInAs(router);

const handleRedirects = async () => {
  // 1. Mark down type of old account
  const originallyHadCompany = !!accountStore.user.activeCompany;
  const originallyHadEnterprise = !!accountStore.user.activeEnterprise;

  // 2. Fetch updated user account
  await accountStore.fetchUserAccount();

  const nowHasCompany = !!accountStore.user.activeCompany;
  const nowHasEnterprise = !!accountStore.user.activeEnterprise;

  // 3. Check against path matches to see if we can stay on the page
  const accountTypeChanged = originallyHadCompany !== nowHasCompany || originallyHadEnterprise !== nowHasEnterprise;

  if (!accountTypeChanged) {
    let targetPath = router.currentRoute.value.path;

    // Step 1: Remove anything after a number
    const indexOfFirstNumber = targetPath.search(/\d/);

    if (indexOfFirstNumber !== -1) {
      targetPath = targetPath.slice(0, indexOfFirstNumber);
    }

    // Step 2: Remove anything after a uuid
    const indexOfFirstUuid = targetPath.search(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/);

    if (indexOfFirstUuid !== -1) {
      targetPath = targetPath.slice(0, indexOfFirstUuid);
    }

    let loopDepth = 0;

    // 3. validate the new path exists & can be accessed by their current role
    while (targetPath.length && loopDepth < 10) {
      loopDepth++;

      // 3a. Resolve the route
      const resolvedRoute = router.resolve({path: targetPath});

      // 3b. Check if the user can access the route
      let canAccessRoute = resolvedRoute.matched.length > 0;

      if (canAccessRoute) {
        const matchedRoute = resolvedRoute.matched[resolvedRoute.matched.length - 1];

        if (matchedRoute.meta.roles) {
          canAccessRoute = matchedRoute.meta.roles.includes(accountStore.user.role);
        }
      }

      // 3c. If they can visit the route, break
      if (canAccessRoute) break;

      // 3d. If not, go up a directory or exit. Aka /opportunities/edit => /opportunities, /opportunities => ""
      const lastSlashIndex = targetPath.lastIndexOf("/");
      if (lastSlashIndex === -1) {
        targetPath = "";
        break;
      }

      targetPath = targetPath.slice(0, lastSlashIndex);
    }

    // 4. If we have a valid target path
    if (targetPath && loopDepth < 10) {

      if (targetPath === router.currentRoute.value.path) {
        // 4a 1. It's the same page we're on
        let hasAtLeastOneParamThatNeedsToChange = false;

        // 4a 2. Handle query params, removing any that are ids (numeric or uuid value)
        const urlParams = new URLSearchParams(window.location.search);
        const urlParamsObject = {};

        for (const [key, value] of urlParams.entries()) {
          if (typeof value !== "string") {
            urlParams.delete(key);
            hasAtLeastOneParamThatNeedsToChange = true;
            continue;
          }

          const hasNumbers = /\d/.test(value);
          const isUuid = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/.test(value);

          if (hasNumbers || isUuid) {
            urlParams.delete(key);
            hasAtLeastOneParamThatNeedsToChange = true;
            continue;
          }

          urlParamsObject[key] = value;
        }

        if (hasAtLeastOneParamThatNeedsToChange) {
          const updatedQuery = urlParams.toString();
          const newUrl = `${targetPath}${updatedQuery ? `?${updatedQuery}` : ""}`;
          window.history.replaceState({}, "", newUrl);

          await nextTick();

          await router.replace({path: targetPath, query: urlParamsObject});
        }

        const pinia = getActivePinia();
        const allStores = pinia._s || [];

        allStores.forEach((store, name) => {
          if (name === "account" || name === "pageMeta") return;
          store.$reset();
        });

        pageReload();

      } else {

        // 4b 1. It's a different page, query params are irrelevant
        await router.push(targetPath);
      }

      toast.success("Successfully switched accounts.");

      return;
    }
  }

  await sendToCorrectDashboard(router, false);
  toast.success("Successfully switched accounts.");
};

const logInAsCandidate = async () => await loginAsCandidateWithoutHandling(async () => handleRedirects());

const logInAsCompany = async (companyId, billableEntityId) => await loginAsCompanyWithoutHandling(companyId, billableEntityId, async () => handleRedirects());

const logInAsEnterprise = async (enterpriseId) => await loginAsEnterpriseWithoutHandling(enterpriseId, async () => handleRedirects());

const switchAccount = (account) => {
  if (account.id === currentAccount.value.id) {
    toast("You are currently using this account.");
    return;
  }

  account.handleSwitch();
  updateOpen(false);
};

const getCompanySubtitle = (company) => {
  if (company.enterprise) {
    const nouns = getEnterpriseNouns(company.enterprise.type);
    return `${nouns.typeName} ${nouns.companyNameSingular}`;
  }

  return "Company Account";
};

const getEnterpriseTypeName = (enterprise) => getEnterpriseNouns(enterprise.type).typeName;

const accountOptions = computed(() => {
  if (!accountStore.user) return [];

  const accounts = [];

  accounts.push({
    id: "personal-account",
    name: accountStore.name,
    subtitle: "Personal Account",
    icon: UserIcon,
    loading: loggingInAsCandidate.value,
    handleSwitch: () => logInAsCandidate(),
  });

  (accountStore.user.companies || []).forEach((company) => {
    accounts.push({
      id: `c-${company.id}`,
      name: company.nickname || company.name,
      subtitle: getCompanySubtitle(company),
      icon: BuildingOfficeIcon,
      loading: loggingInAsCompanyId.value === company.id,
      handleSwitch: () => logInAsCompany(company.id, company.billableEntityId),
    });
  });

  (accountStore.user.enterprises || []).forEach((enterprise) => {
    const typeName = getEnterpriseTypeName(enterprise);

    accounts.push({
      id: `e-${enterprise.id}`,
      name: enterprise.name,
      subtitle: `${typeName} Account`,
      icon: BuildingOffice2Icon,
      loading: loggingInAsEnterpriseId.value === enterprise.id,
      handleSwitch: () => logInAsEnterprise(enterprise.id),
    });
  });

  if (accountStore.user.impersonate) {
    accounts.push({
      id: "stop-impersonation",
      name: "Stop Impersonating",
      subtitle: accountStore.name,
      icon: XCircleIcon,
      href: "/stop-impersonation"
    });
  }

  if (accountStore.user.isSuperAdmin) {
    accounts.push({
      id: "super-admin",
      name: "Super Admin",
      subtitle: "2FA Required",
      icon: LockClosedIcon,
      href: "/admin/dashboard"
    });
  }

  return accounts;
});

const currentAccount = computed(() => {
  if (accountStore.user.activeCompany) {
    return accountOptions.value.find((account) => account.id === `c-${accountStore.user.activeCompany.id}`);
  }

  if (accountStore.user.activeEnterprise) {
    return accountOptions.value.find((account) => account.id === `e-${accountStore.user.activeEnterprise.id}`);
  }

  return accountOptions.value.find((account) => account.id === "personal-account");
});
</script>
