<template>
  <main
    id="main"
    class="relative h-full w-full flex-1 overflow-y-auto pb-12 transition-all lg:mb-0"
    :class="!pageMetaStore.forceHideMobileMenu && 'mb-12'"
    :style="route.meta?.removePaddingBottom && 'padding-bottom:0;'">

    <div
    :class="!route.meta?.hideHeader && 'min-h-[101vh]'">

      <div ref="banners">
        <slot name="banner"/>
      </div>

      <MainMenuHeader v-if="!route.meta?.hideHeader" v-bind="props"/>

      <div
        v-if="route.meta?.useGradientBanner"
        :style="gradientStyles"
        class="absolute right-0 left-0 h-48 bg-gradient glint"
      />

      <slot/>

    </div>

  </main>
</template>

<script setup>
import {usePageMetaStore} from "../stores/PageMetaStore.js";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import MainMenuHeader from "./MainMenuHeader.vue";
import {useRoute} from "vue-router";

const props = defineProps({
  isCompany: Boolean,
  isEnterprise: Boolean,
  settingsLink: String,
  accountLink: String,
  notificationLink: String,
});

const pageMetaStore = usePageMetaStore();
const route = useRoute();

const bannerBottomPx = ref(0);
const banners = ref(null);

const gradientStyles = computed(() => `z-index: -1;--glint-opacity:0.04; top: ${bannerBottomPx.value}px;`);

const getBannerHeightPx = () => {
  if (!banners.value || !banners.value?.clientHeight) return 0;
  return banners.value.clientHeight;
};

const observer = new ResizeObserver(() => bannerBottomPx.value = getBannerHeightPx());

onMounted(() => {
  bannerBottomPx.value = getBannerHeightPx();
  observer.observe(banners.value);
});

onBeforeUnmount(() => observer.disconnect());
</script>
