<template>
  <RouterLink
    :to="notificationLink"
    class="ml-auto hidden p-4 md:block">
          <span
            class="relative"
            :class="showNotifications && notificationClasses">
            <Component
              :is="showNotifications ? BellAlertIcon : BellIcon"
              :class="showNotifications ? 'h-4 w-4 -mb-px' : 'h-5 w-5'"/>
            <span
              v-if="showNotifications">
              {{ pageMetaStore.unreadNotificationCount }}
            </span>
          </span>
  </RouterLink>
</template>

<script setup>
import {BellAlertIcon} from "@heroicons/vue/24/solid/index.js";
import {BellIcon} from "@heroicons/vue/24/outline/index.js";
import {usePageMetaStore} from "../stores/PageMetaStore";
import {computed} from "vue";

const props = defineProps({
  notificationLink: String,
  useGradientBanner: Boolean,
});

const pageMetaStore = usePageMetaStore();

const notificationClasses = computed(() => {
  const classList = ["rounded-full", "gap-1", "font-bold", "py-2", "px-2.5", "flex", "items-center", "text-sm", "-mr-4", "hover:opacity-95", "ring", "ring-offset-0", "hover:ring-opacity-20", "active:ring-opacity-0", "ring-opacity-30", "transition-all"];

  if (props.useGradientBanner) classList.push("bg-white text-red-600 hover:bg-white ring-white shadow");
  else classList.push("bg-red-50 text-red-600 hover:bg-red-100 hover:text-red-700 ring-red-100");

  return classList.join(" ");
});

const showNotifications = computed(() => {
  return !!pageMetaStore.unreadNotificationCount;
});
</script>
