<template>
  <MenuContainer
    is-enterprise
    :menu-items="menuItems"
    dashboardLink="/enterprise-groups"
    accountLink="/enterprise-account"
  >
    <slot/>
  </MenuContainer>
</template>

<script setup>
import MenuContainer from "../components/MenuContainer.vue";
import {BuildingLibraryIcon, BuildingOffice2Icon, CogIcon, KeyIcon, UsersIcon} from "@heroicons/vue/24/outline";
import {
  BuildingLibraryIcon as BuildingLibraryIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  CogIcon as CogIconSolid,
  KeyIcon as KeyIconSolid,
  UsersIcon as UsersIconSolid,
} from "@heroicons/vue/24/solid";
import {computed, provide} from "vue";
import {useAccountStore} from "../stores/AccountStore";
import {ENTERPRISE_OWNER} from "../constants/Roles";
import {getEnterpriseNouns} from "../helpers/EnterpriseNouns";

const accountStore = useAccountStore();

const activeEnterpriseNouns = computed(() => getEnterpriseNouns(accountStore.user?.activeEnterprise?.type));
provide("activeEnterpriseNouns", activeEnterpriseNouns);

const menuItems = computed(() => {
  const baseItems = [
    {
      name: activeEnterpriseNouns.value.groupPlural,
      link: "/enterprise-groups",
      icon: UsersIcon,
      iconSolid: UsersIconSolid,
    },
    {
      name: activeEnterpriseNouns.value.companyNamePlural,
      link: "/enterprise-companies",
      icon: BuildingOffice2Icon,
      iconSolid: BuildingOffice2IconSolid,
    },
    {
      name: "Access Manager",
      link: "/enterprise-access-manager",
      icon: KeyIcon,
      iconSolid: KeyIconSolid,
    },
    {
      name: "Billing",
      link: "/enterprise-billing-groups",
      icon: BuildingLibraryIcon,
      iconSolid: BuildingLibraryIconSolid,
    },
  ];

  if (accountStore.user?.activeEnterprise?.role === ENTERPRISE_OWNER) {
    baseItems.push({
      name: "Settings",
      link: "/enterprise-settings",
      icon: CogIcon,
      iconSolid: CogIconSolid,
    });
  }

  return baseItems;
});

</script>
