import * as Sentry from "@sentry/vue";

/*
 * Setup Sentry for error tracking
 *
 * @param {string} dsn
 * @param {import("vue").App} app
 * @param {import("vue-router").Router} router
 */
export default function setupSentry (dsn, app, router) {
  if (!dsn || !app || !router) return;

  Sentry.init({
    app,
    dsn: dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost", "businessdraft.com/api"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  });
};
