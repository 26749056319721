import {defineStore} from "pinia";
import {ref} from "vue";
import NotificationApi from "../api/NotificationApi.js";
import {useAccountStore} from "./AccountStore";
import {ENV_DEMO} from "../constants/EnvironmentType";

export const usePageMetaStore = defineStore("pageMeta", () => {
  const pageTitle = ref("");
  const menuLeftWidth = ref("0px");
  const forceHideMobileMenu = ref(false);
  const unreadNotificationCount = ref(0);

  const NOTIFICATION_RECHECK_INTERVAL_MS = 1000 * 60 * 5; // 5 minutes

  const getUnreadNotificationCount = () => {
    const accountStore = useAccountStore();

    if (!document.hidden && accountStore.isLoggedIn && import.meta.env.VITE_ENV !== ENV_DEMO) {
      new NotificationApi()
        .unreadCount()
        .then((response) => unreadNotificationCount.value = response.count);
    }

    setTimeout(() => {
      getUnreadNotificationCount();
    }, NOTIFICATION_RECHECK_INTERVAL_MS);
  };

  const setPageTitle = (title, includeDashBd = true) => {
    pageTitle.value = title;
    document.title = title + (includeDashBd ? " - Business Draft" : "");
  };

  getUnreadNotificationCount();

  const $reset = () => {
    // Do nothing, this store should not be reset
  };

  return {
    pageTitle,
    menuLeftWidth,
    forceHideMobileMenu,
    unreadNotificationCount,
    setPageTitle,
    $reset,
  };
});
