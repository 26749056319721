import axios from "axios";
import BaseApi from "./BaseApi";

export default class AccountApi extends BaseApi {
  constructor() {
    super("users");
  }

  static login(email, password, rememberMe = false, setCompanyId = null) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      email,
      password,
      remember_me: rememberMe,
      ...timezone && { timezone },
    };

    if (setCompanyId) return axios.post(`/login?set_company_id=${setCompanyId}`, data);
    return axios.post("/login", data);
  }

  static logout = () => axios.post("/logout");

  static registerUser = (data) => axios.post("/register", data);

  static forgotPassword = (email) => axios.post("/forgot-password", { email });

  static resetPassword = (data) => axios.post("/reset-password", data);

  static twoFactorChallenge = (data) => axios.post("/two-factor-challenge", data);

  confirmPasswordStatus = () => axios.get("/user/confirmed-password-status");

  confirmUserPassword = (data) => axios.post("/user/confirm-password", data);

  enableTwoFactorAuth = () => axios.post("/user/two-factor-authentication");

  disableTwoFactorAuth = () => axios.delete("/user/two-factor-authentication");

  twoFactorQrCode = () => axios.get("/user/two-factor-qr-code");

  twoFactorRecoveryCodes = () => axios.get("/user/two-factor-recovery-codes");

  confirmedTwoFactorAuth = (data) => axios.post("/user/confirmed-two-factor-authentication", data);

  updatePassword = (data) => axios.put("/user/password", data);

  snooze = (id, data) => axios.post(`${this.baseUrl}/${id}/snooze`, data);
  unSnooze = (id) => axios.post(`${this.baseUrl}/${id}/unsnooze`);
  withdrawApplications = (id) => axios.post(`${this.baseUrl}/${id}/withdraw-applications`);
  optOutOfAutoSnooze = (id) => axios.post(`${this.baseUrl}/${id}/opt-out-of-auto-snooze`);
  checkSession = () => axios.get(`${this.baseUrl}/check-session`);
  static getCsrfToken() {
    return axios.get("/api/sanctum/csrf-cookie");
  }

  static getUserData = () => axios.get("/api/users/self");

  static checkIfEmailExists = (data) =>
    axios.post("/api/users/check-email-exists", data);

  static checkIfPhoneExists = (data) =>
    axios.post("/api/users/check-phone-number-exists", data);

  static checkIfUsernameTaken = (data) =>
    axios.post("/api/users/check-username-taken", data);

  static sendVerificationSms = (data) =>
    axios.post("/api/send-verification-sms", data);

  static verifySmsCode = (data) =>
    axios.post("/api/verify-sms-code", data);

  getGiveawayInfo = () => axios.get("/api/users/giveaway-info");

}
