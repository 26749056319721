export const intercomIncludedOn = [
  "/confirm",
  "/create-account",
  "/dashboard",
  "/company-dashboard",
  "/opportunities",
  "/company-opportunities",
  "/company-candidates",
  "/applications",
  "/interviews",
  "/company-interviews",
  "/company-profile",
  "/profile",
  "/manage-team",
  "/account",
  "/company-account",
  "/notifications",
  "/company-notifications",
  "/notification-preferences",
  "/company-notification-preferences",
  "/launch-pad",
  "/integrations",
];
